/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
*,
*:hover,
*:focus {
  outline-color: #037a35;
}
.ant-badge-count {
  background: #f5222d !important;
}
.ant-btn:hover,
.ant-btn:focus {
  background-color: #151716 !important;
  color: #fff !important;
  border-color: #151716 !important;
}
.ant-btn-primary {
  border-color: #037a35 !important;
  background-color: transparent !important;
}
.ant-btn-primary:hover {
  border-color: #037a35 !important;
  background-color: #037a35 !important;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
  opacity: 0.4;
  color: #037a35 !important;
  border-color: #037a35 !important;
  background-color: #fff !important;
}
.ant-btn-primary:focus {
  color: #037a35 !important;
  background-color: #fff !important;
  border-color: #037a35 !important;
}
.ant-btn-primary.transparent-bg {
  background-color: transparent !important;
}
.ant-btn-dashed {
  border-color: #e5f5ea !important;
  color: #037a35 !important;
  border-style: solid !important;
  background-color: #e5f5ea !important;
}
.ant-btn-dashed:hover {
  border-color: #e5f5ea !important;
  color: #037a35 !important;
  border-style: solid !important;
  background-color: #e5f5ea !important;
  -webkit-filter: brightness(0.95);
          filter: brightness(0.95);
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover {
  opacity: 0.4;
  border-color: #e5f5ea !important;
  color: #037a35 !important;
  border-style: solid !important;
  background-color: #e5f5ea !important;
}
.ant-btn-dashed:focus {
  border-color: #e5f5ea !important;
  color: #037a35 !important;
  border-style: solid !important;
  background-color: #e5f5ea !important;
  -webkit-filter: brightness(0.95);
          filter: brightness(0.95);
}
.ant-btn-link {
  border-radius: 0 !important;
  padding: 0 !important;
  height: auto !important;
}
.ant-btn-link:not([disabled]):hover,
.ant-btn-link:not([disabled]):active,
.ant-btn-link:not([disabled]):focus {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #037a35 !important;
}
.ant-btn-link:not([disabled]):hover,
.ant-btn-link:not([disabled]):active,
.ant-btn-link:not([disabled]):focus,
.ant-btn-link:not([disabled]):hover > span,
.ant-btn-link:not([disabled]):active > span,
.ant-btn-link:not([disabled]):focus > span {
  text-decoration: underline !important;
}
.button .ant-btn {
  background: #f6f6f6;
  height: 40px;
}
.custom-collapse-style.ant-collapse-borderless {
  background: transparent;
}
.custom-collapse-style.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.custom-collapse-style .ant-collapse-header {
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  padding-left: 0;
}
.custom-collapse-style.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0px;
}
.custom-collapse-style.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header span.anticon {
  right: 0px;
}
.ant-form-item {
  margin-bottom: 0 !important;
}
.ant-drawer-body {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  height: 100%;
}
.ant-tabs {
  overflow: unset !important;
}
.ant-tabs-bar {
  margin: unset !important;
}
.ant-tabs-nav {
  margin: 0 !important;
}
.ant-tabs-content-holder {
  background: white;
}
.ant-select .ant-select-selection-item {
  color: black;
}
.ant-select-dropdown-menu {
  max-height: 400px !important;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e5f5ea !important;
}
.has-success.has-feedback .ant-form-item-children-icon,
.has-warning.has-feedback .ant-form-item-children-icon,
.has-error.has-feedback .ant-form-item-children-icon,
.is-validating.has-feedback .ant-form-item-children-icon {
  font-size: 24px !important;
  right: 6px !important;
}
.ant-carousel .slick-slider .slick-dots li {
  margin: 0 5px;
}
.ant-carousel .slick-slider .slick-dots li button {
  background: #037a35 !important;
  border-radius: 5px;
}
.ant-modal-footer {
  border-top: none !important;
}
.ant-modal-close-x {
  font-size: 22px;
  line-height: 76px;
}
.ant-modal-title {
  font-size: 24px !important;
  font-weight: 300 !important;
  line-height: 32px !important;
}
.ant-modal-footer {
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 24px !important;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 24px !important;
  font-weight: 300 !important;
  line-height: 32px !important;
}
.ant-modal-confirm-body > .anticon {
  font-size: 28px !important;
}
.ant-tooltip-inner {
  background-color: black !important;
}
.ant-tooltip-arrow-content {
  background-color: black !important;
}
.ant-form-item-label,
.ant-form-item-label > label {
  font-size: 12px !important;
  line-height: 14px !important;
  text-transform: uppercase;
  color: #757575 !important;
  padding: 0 0 4px 0 !important;
  white-space: nowrap;
}
.ant-form-large .ant-form-item-label > label {
  height: auto !important;
}
.ant-table {
  color: #151716 !important;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #e5f5ea !important;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  border-bottom-color: #d5d5d5 !important;
}
.ant-table-thead > tr > th {
  text-transform: uppercase;
  font-weight: normal !important;
}
.ant-table-tbody td.ant-table-column-sort,
.admin .ant-table-thead th.ant-table-column-sort {
  background: unset;
}
.disable-header-sorting .ant-table-thead th.ant-table-column-has-sorters,
.disable-header-sorting .ant-table-thead th.ant-table-column-has-sorters:hover {
  cursor: auto;
  background: unset !important;
}
.ant-checkbox-inner,
.ant-tree-checkbox-inner {
  border-radius: 3px !important;
}
.ant-checkbox-group {
  display: -webkit-flex !important;
  display: -moz-box !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
     -moz-box-orient: vertical !important;
     -moz-box-direction: normal !important;
          flex-direction: column !important;
}
.ant-checkbox-group > label {
  margin-top: 10px !important;
  color: #151716 !important;
}
.ant-input-prefix {
  margin-right: 8px !important;
}
.ant-input-lg {
  height: 40px;
}
.ant-select-disabled .ant-select-selector,
.ant-input.ant-input-disabled {
  border-color: #f6f6f6 !important;
}
.ant-input-affix-wrapper-lg .ant-input-lg {
  height: unset;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300 !important;
  line-height: 1.25;
}
h1 {
  font-size: 24px;
}
h2 {
  font-size: 23px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 17px;
}
@media (max-width: 768px) {
  h1 {
    font-size: 21px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 17px;
  }
  h4 {
    font-size: 15px;
  }
}
@media (max-width: 576px) {
  h1 {
    font-size: 17px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
  h4 {
    font-size: 13px;
  }
  p {
    font-size: 16px;
  }
}
.react-grid-layout {
  position: relative;
  -webkit-transition: height 200ms ease;
  -moz-transition: height 200ms ease;
  transition: height 200ms ease;
}
.react-grid-item {
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  transition: all 200ms ease;
  -webkit-transition-property: left, top;
  -moz-transition-property: left, top;
  transition-property: left, top;
}
.react-grid-item img {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.react-grid-item.cssTransforms {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -moz-transition-property: transform, -moz-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform, -moz-transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}
.react-grid-item.react-draggable-dragging {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
  z-index: 3;
  will-change: transform;
}
.react-grid-item.dropping {
  visibility: hidden;
}
.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  -webkit-transition-duration: 100ms;
     -moz-transition-duration: 100ms;
          transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}
.react-grid-item > .react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}
.react-resizable-hide > .react-resizable-handle {
  display: none;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  -webkit-transform: rotate(90deg);
     -moz-transform: rotate(90deg);
          transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
          transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  -webkit-transform: rotate(270deg);
     -moz-transform: rotate(270deg);
          transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  -webkit-transform: rotate(135deg);
     -moz-transform: rotate(135deg);
          transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  -webkit-transform: rotate(315deg);
     -moz-transform: rotate(315deg);
          transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  -webkit-transform: rotate(225deg);
     -moz-transform: rotate(225deg);
          transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  -webkit-transform: rotate(45deg);
     -moz-transform: rotate(45deg);
          transform: rotate(45deg);
}
.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  -webkit-transform: rotate(90deg);
     -moz-transform: rotate(90deg);
          transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
          transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  -webkit-transform: rotate(270deg);
     -moz-transform: rotate(270deg);
          transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  -webkit-transform: rotate(135deg);
     -moz-transform: rotate(135deg);
          transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  -webkit-transform: rotate(315deg);
     -moz-transform: rotate(315deg);
          transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  -webkit-transform: rotate(225deg);
     -moz-transform: rotate(225deg);
          transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  -webkit-transform: rotate(45deg);
     -moz-transform: rotate(45deg);
          transform: rotate(45deg);
}
#__next {
  height: 100%;
}
.app {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  height: 100%;
}
.pointer {
  cursor: pointer;
}
.mobile {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}
@media (min-width: 768px) {
  .mobile {
    display: none;
  }
}
.desktop {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}
@media (max-width: 768px) {
  .desktop {
    display: none !important;
  }
}
.page {
  width: 100%;
  min-height: 100%;
  height: 0px;
  /* fix big in safari where you cannot set a childs height to 100%, works in chrome and safari without this */
  background-color: #ffffff;
}
.page-wrap {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  width: 100%;
  margin-top: 72px;
}
@media (min-width: 992px) {
  .page-wrap {
    min-height: -webkit-calc(100% - 70px);
    min-height: -moz-calc(100% - 70px);
    min-height: calc(100% - 70px);
    width: -webkit-calc(100% - 255px);
    width: -moz-calc(100% - 255px);
    width: calc(100% - 255px);
    margin-left: 255px;
  }
}
.block {
  background-color: #ffffff;
  padding: 16px;
  border-right: 1px solid #e5e5e5;
  border-radius: 4px;
}
.admin .top {
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  margin-bottom: 16px;
}
.admin .top .header {
  display: grid;
  grid-template-columns: auto auto;
  background-color: #f6f6f6;
  padding: 22px 50px;
}
.admin .top .header h1,
.admin .top .header h2,
.admin .top .header h3,
.admin .top .header h4 {
  margin: 0;
}
.admin .top .header h1:first-letter,
.admin .top .header h2:first-letter,
.admin .top .header h3:first-letter,
.admin .top .header h4:first-letter {
  text-transform: uppercase;
}
.admin .top .buttons {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
          justify-content: flex-end;
}
.admin .top .actions {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  padding: 24px 50px;
}
.admin .top .actions .ant-row {
  margin-bottom: 0 !important;
}
.admin .top .tabs {
  grid-column: 1 / span 2;
  background-color: #f6f6f6;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 22px;
}
.admin .ant-table-cell:first-child {
  padding-left: 50px !important;
}
.admin .ant-table-cell:last-child {
  padding-right: 50px !important;
}
.table {
  display: table;
}
.table-row {
  display: table-row;
}
.table-cell {
  display: table-cell;
}
.full-width {
  width: 100%;
}
.push-right {
  text-align: right;
}
.vertical-spacing {
  margin-bottom: 1em;
}
.centered {
  text-align: center;
}
@media (min-width: 768px) {
  .home .article {
    height: 100%;
  }
}
.article-container {
  max-width: 600px;
  padding: 20px;
}
.article-container .article {
  margin-bottom: 20px;
}
.padded {
  padding: 24px;
}
@media (max-width: 768px) {
  .padded {
    padding: 16px;
  }
}
@media (max-width: 576px) {
  .padded {
    padding: 12px;
  }
}
.padded-wide {
  padding: 40px;
}
@media (max-width: 768px) {
  .padded-wide {
    padding: 24px;
  }
}
@media (max-width: 576px) {
  .padded-wide {
    padding: 16px;
  }
}
.button-icon {
  font-size: 20px;
  color: #1497d5 !important;
}
.button-icon.close {
  color: #ee8233 !important;
}
.admin-unit {
  position: relative;
  padding: 6px;
  margin: 6px;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  background-color: #ffffff;
}
.admin-unit.add {
  border-color: #eeeeee;
}
.repeatable {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 12px;
  position: relative;
  margin-bottom: 12px;
}
.item-add {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  cursor: pointer;
}
.item-add > * {
  margin-right: 5px;
}
.item-delete {
  position: absolute;
  right: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
          transform: translateY(-50%);
}
.gojs *:focus {
  outline-color: transparent !important;
}
.ant-modal.onboarding {
  width: -webkit-calc(100% - 40px) !important;
  width: -moz-calc(100% - 40px) !important;
  width: calc(100% - 40px) !important;
}
@media (max-width: 768px) {
  .ant-modal.onboarding {
    width: -webkit-calc(100% - 10px) !important;
    width: -moz-calc(100% - 10px) !important;
    width: calc(100% - 10px) !important;
  }
}
.round .ant-select-selector {
  border-radius: 20px !important;
}
.round .ant-select-selection-item {
  color: #757575;
}
@media (min-width: 1200px) {
  .ant-modal.onboarding {
    width: 1160px !important;
  }
}
/* Readonly components */
form.readonly-form * {
  pointer-events: none;
}
